@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@300;600&display=swap');
.img-container{
    height: 100%;
    min-height: 20rem;
    max-height: 20rem;
    
}
.img-container:hover{
    cursor: pointer;
}
.cover{
min-width: 100%;
max-width: 100%;
} 

.title{
    margin-top: 2rem;
    font-weight: bold;
    color: #EDEDED;
    font-size: 5rem;
    font-family: 'Overpass', sans-serif;
}

.modal-title{
    font-family: 'Overpass', sans-serif ;
}

.bg-img{
    padding: 3rem;
    margin-bottom: 3rem;
    text-align: center;
    background: rgb(8,32,50);
    
}  

.img-modal{
    max-width: 410px;
}


.icons{
    width: 30px;
    height: 30px;
    margin-right: 15px;
}
.icon-mail{
    width: 50px;
    height: 50px;
    margin-right: 15px;
}
.btn-footer{
    width: 100%;
    background-color: #082032 !important;
    border: 0 !important;
}
.card-title{
    font-family: 'Overpass', sans-serif ;
}

@media (max-width: 600px) {
    .img-modal{
        max-width: 200px;
        max-height: 200px;
    }
    .title{
        font-size: 35px;
        }
    .bg-img{
        padding: 0;
        background: rgb(8,32,50); 
    }  
}

.text-pe{
    color: #082032;
    font-family: 'Overpass', sans-serif;
}