.pin-icon{
    color: #EE3036;
    font-size: 35px;
    vertical-align: text-top;
}

.pin-text{
    color: #EE3036;
    font-weight: bold;
}
.map-container{
    height: 50vh;
    width: 100%;
}

.font{
    font-family: 'Overpass', sans-serif;
    font-weight: bold;
    font-size: 20px;
    text-align: justify;
}