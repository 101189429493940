@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@300&display=swap');

.main-container{
    /* min-height: 60vw; */
    /* background-color: #082032; */
}
.text-p{
    color: #ededed;
    font-family: 'Overpass', sans-serif;
}
.text-error{
    color: #EE3036;
    font-family: 'Overpass', sans-serif;
    font-size: 13px;
    font-weight: bold;
}
.img-row{
    /* margin-top: 3rem !important; */
    width: 10rem;
    height: 10rem;
    text-align: center;
}
.text-contact{
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 3rem;
}
.subtitle-contact{
    color:#ededed;
    margin-top: 2rem;
}
.form-main{
    margin-bottom: 3rem;
    margin-top: 5rem !important;
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.img-container-contact{
    background-color: #EE3036;
    padding: 9rem 0 !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.m-20{
   margin: 6rem 8rem !important;
    background-color: #082032;
    border-radius: 20px;
}
.btn-confirm{
color: #ededed !important;
background-color: #082032 !important;
border: 1px solid;
border-color: #ededed !important;
width: 30%;
}
@media (max-width: 600px) {
    .form-main{
        padding-right: 0 !important;
    }

    .m-20{
        margin: 2rem 0 !important;
     }

     .img-container-contact{
        padding: .5rem 0 !important;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 0;
    }
    .main-container{
       padding: 0;
    }

 
    .form-main{
        margin-bottom: 1rem;
        margin-top: 2rem !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .btn-confirm{
        color: #ededed !important;
        background-color: #082032 !important;
        border: 1px solid;
        border-color: #ededed !important;
        width: 100%;
        height: 3rem;
        margin-bottom: 2rem;
        }

        .img-row{
            /* margin-top: 3rem !important; */
            width: 5rem;
            height: 5rem;
            text-align: center;
        
        }
}

