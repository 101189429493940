@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@300&display=swap');
.info{
    background-color: #1658B7;
    width: 100%;
    text-align: center;
    font-family: 'Overpass', sans-serif;
    color: #EDEDED;
    margin-right: 15px;
}

span{
    margin-right: 20px;
}
.icon{
    margin-right: 5px;
}
.info-header-text{
    font-size: 25px;
    vertical-align: middle;
}

@media (max-width: 600px) {
    .info-header-text{
        font-size: 15px;
        vertical-align: middle;
    }
  }