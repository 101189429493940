@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@300&display=swap');
.home-container{
    background-color: #EDEDED;
    padding-bottom: 10px;
}

.logo{
   width: 100% ;
     /* height: 100%;
    padding: 0 !important; */
}

.marginTop{
    margin-top: 4rem !important;
}

.marginBot{
    margin-bottom: 4rem !important;
}

.logoTools{
    width: 80% !important; 
}

.h1-font{
    /*width: 100% !important;
    height: 100%;
    background-color: #EE3036;
    background-size: contain;*/
   
    font-family: 'Overpass', sans-serif;
    font-weight: bold;
}

h2{
    font-family: 'Overpass', sans-serif !important;
}

span{
    font-family: 'Overpass', sans-serif;
    font-size: larger;
    text-align: justify;
}

.text-color-w{
    color: #EDEDED;
background-color: #082032;
}

.text-color-b{
    color: #082032;
}

.minibanner {
    background:#EDEDED;
    background: linear-gradient(to right bottom, #EDEDED 50%, #082032 50.9%);
    
    display:inline-block;
    padding:0.75em 2.0em;
    height: 100px;
  }

.minibanner-left {
    background:#EDEDED;
    background: linear-gradient(to right bottom, #082032 50%, #EDEDED 50%);
    
    display:inline-block;
    padding:0.75em 2.0em;
    height: 100px;
  }
  
.brand{
    height: 15rem;
    margin-left: auto;
    margin-right: auto;
 }

 .section{
    background-color: #082032;
 }

 .iconsh{
    color: #EDEDED;
    font-size: 100px;
    margin-bottom: 25px;
}
.ml-100{
    margin-left: 100px;
}

.mt-10{
    margin-top: 20px;
}
.txt-align-title{
    text-align: right;
}

@media (max-width: 600px) {
    .ml-100{
        margin-left: 0;
    }
    .brand{
        height: 5rem;
     }
     .txt-align-title{
         text-align: center;
     }
     .iconsh{
        font-size: 50px;
    }
}