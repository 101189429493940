
.contac-info{
    color: #EDEDED;
    font-size: 3rem;
}

h1{
    font-size: 30px;
}

.text{
    color: #EDEDED;
}

.margin-bot{
    margin-bottom: 1rem;
}

.icons-footer{
    color: #EDEDED;
    font-size: 60px;
}

.icons-cont{
    color: #EDEDED;
    font-size: 25px;
    margin-right: 10px;
}

.info-footer{
    font-size: 25px;
}
@media (max-width: 600px) {
    .info-footer{
        font-size: 20px;
    }
    .icons-cont{
        font-size: 15px;
    }
}

.expand{
    overflow-wrap: break-word;
}
