
@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@300&display=swap');

.header{
    font-size: 1.8rem;
    font-family: 'Overpass', sans-serif;
}

.link{
    text-decoration: none;
    color: #EDEDED;
    padding: 8px;
}

.link:hover, link:active, link:focus{
    text-decoration: none;
    color: rgba(255,255,255,.55);
    cursor: pointer;
}

.dropdown-menu{
    background-color: #082032 !important;
}

.bkg-ddl{
    color: black;
}

.logo-img{
    height: 100px;
    margin-left: 20px; 
    margin-right: 100px;
}

.menu-container{
    width: 100%;
    justify-content: space-around;
    padding: 0px 100px;
}

.header-container{
    background-color: #082032;
}

.navbar-light .navbar-nav .nav-link{
    padding: 0;
    color: #EDEDED !important;
}

.navbar-light .navbar-toggler-icon{
    background-image: url('../../img/burger.png') !important;
}

.navbar-toggler:focus{
    box-shadow: none !important;
}

.dropdown-item:focus, .dropdown-item:hover{
    background-color: #082032 !important;
}

@media (max-width: 600px) {
    .logo-img{
        /* width: 70px; */
        height: 50px;
        margin-left: 0;
    }

    .menu-container{
        
        padding: 0;
    }

  
  }